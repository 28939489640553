import React from 'react';
import HomeStyle from './Home.style';

function Home() {
  return (
    <HomeStyle className="home">
      <p>nathat.com</p>
    </HomeStyle>
  );
}

export default Home;
